(function() {

  var mainSliderSelector = '.u-main-slider',
      mainContentSelector = '.u-main-content',
      $initialActiveSlide = $('.u-main-slider__item[data-page-url^="' + location.origin + location.pathname + '"]'),
      initialActiveSlideIndex = $initialActiveSlide.index(),
      sliderOptions = {
        infinite: true,
        arrows: true,
        autoplay: $initialActiveSlide.length === 0,
        autoplaySpeed: 10000,
        appendArrows: '.u-controls',
        prevArrow:'<button class="u-main-slider__prev u-svg-button u-svg-button--style-bordered"><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 62 62" style="enable-background:new 0 0 62 62;" xml:space="preserve"><path class="u-svg-button__background" d="M31,61.5C14.2,61.5,0.5,47.8,0.5,31C0.5,14.2,14.2,0.5,31,0.5c16.8,0,30.5,13.7,30.5,30.5 C61.5,47.8,47.8,61.5,31,61.5z M31,1.5C14.7,1.5,1.5,14.7,1.5,31S14.7,60.5,31,60.5S60.5,47.3,60.5,31S47.3,1.5,31,1.5z"/><path class="u-svg-button__arrow" d="M35,37.2L33.3,39L26,31.5l7.3-7.5l1.7,1.8l-5.6,5.7L35,37.2z"/></svg></button>',
        nextArrow: '<button class="u-main-slider__next u-svg-button"><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 62 62" style="enable-background:new 0 0 62 62;" xml:space="preserve"><circle class="u-svg-button__background" cx="31" cy="31" r="30"/><path class="u-svg-button__border" d="M31,61.5C14.2,61.5,0.5,47.8,0.5,31C0.5,14.2,14.2,0.5,31,0.5c16.8,0,30.5,13.7,30.5,30.5	C61.5,47.8,47.8,61.5,31,61.5z M31,1.5C14.7,1.5,1.5,14.7,1.5,31S14.7,60.5,31,60.5S60.5,47.3,60.5,31S47.3,1.5,31,1.5z"/><path class="u-svg-button__arrow" d="M27,37.2l1.7,1.8l7.3-7.5L28.7,24L27,25.8l5.6,5.7L27,37.2z"/></svg></button>'
      };

  function replaceContent($slideItem) {
      $(document).trigger('destroy-content');
      $(mainContentSelector).html($slideItem.data("page"));
      $(document).trigger('content-ready', $slideItem);

      
        anime.timeline({
            easing: 'linear',
            begin: function() {
                $(mainContentSelector).parent().children('.spinner').remove();
            }
        })
        .add({
            targets: $(mainContentSelector)[0],
            duration: 200,
            opacity: [0,1]
        })
  }
  
  function loadContent(slide) {
      var $slideItem = $(slide),
          url = $slideItem.data("page-url");

  
      if(!$slideItem.data("page")) {

        anime.timeline({
            easing: 'linear',
            begin: function() {
                $(mainContentSelector).parent().prepend($('<div class="spinner"></div>'));
            }
        })
        .add({
            targets: $(mainContentSelector)[0],
            duration: 200,
            opacity: [1,0]
        });

        $(mainContentSelector)

          $.get(url, function(data) {
              $slideItem.data("page-content-html", data);

              var onlyMainContent = data.substring(data.indexOf('<main'), data.indexOf('</main>'));
              onlyMainContent = onlyMainContent.substring(onlyMainContent.indexOf('>') + 1);

              $slideItem.data("page", data.indexOf('<html') >= 0 ? onlyMainContent : data);
              $slideItem.data("page-title", data.indexOf('<html') >= 0 ?  $(data).filter('title').text().trim() : $slideItem.find('.u-teaser__headline').text());
              replaceContent($slideItem);
          });
      }
      else {
          replaceContent($slideItem);
      }
  }

  $(function() {

      var $mainSlider = $(mainSliderSelector),
          dontLoadContent = false;

    if(!$mainSlider) return;

      // init slider
      mainSlider = $mainSlider.slick(sliderOptions);

      
      // load content on slide change
      $mainSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
          $nextSlide = $(slick.$slides[nextSlide]).find(".u-main-slider__item");
          if(!dontLoadContent) {
              loadContent($nextSlide);
          } else {
              dontLoadContent = false;              
            $(document).trigger('destroy-content');
            $(document).trigger('content-ready', $nextSlide);
          }

          $('.u-lang-switcher__item--de .u-lang-switcher__link').attr('href', $nextSlide.data('page-url-de'));
          $('.u-lang-switcher__item--en .u-lang-switcher__link').attr('href', $nextSlide.data('page-url-en'));

        });
        
    if(location.pathname.length > 1 && $initialActiveSlide.length > 0) {
        dontLoadContent = true;
        $mainSlider.slick('slickGoTo', initialActiveSlideIndex, true);
    } else if($(mainContentSelector).children().length === 0) {
        // init first slide content
        loadContent($mainSlider.find(".slick-active .u-main-slider__item").eq(0));
    }
    else {
        // trigger content ready on init
        $(document).trigger('content-ready', $mainSlider.find(".slick-active .u-main-slider__item").eq(0));
    }

    window.onpopstate = function(e){
        $('body').addClass('disable-scroll-event');
        if(e.state){
            $(window).scrollTop();
            document.title = e.state.pageTitle;

            var currentId = $(':not(.slick-cloned) > div > .u-main-slider__item[data-page-url^="' + location.origin + location.pathname + '"]').closest('[data-slick-index]').data('slick-index') || 0;
            $mainSlider.slick('slickGoTo', currentId, true);
        }
    };

  });
})();

// destroy inView Objects
function destroyInViewOnContentDestroy(inViewObject) {
    $(document).on('destroy-content', function() {
        inViewObject.handlers.enter = [];
        inViewObject.handlers.exit = [];
        inViewObject.current = [];
        inViewObject.elements = [];
    });
}


(function() {


})();
(function($, window, document) {
    
    $(document).on("click", ".u-accordion__header, .u-accordion__toggler", function(e) {
        var $item = $(e.currentTarget),
            $accordion = $item.closest(".u-accordion"),
            group = $accordion.data("accordion-group"),
            isOpen = $accordion.hasClass("is-open");

        if(!isOpen && group) {
            $(".u-accordion[data-accordion-group='" + group + "']").removeClass("is-open")
        }

        $accordion.toggleClass("is-open", !isOpen);
        if(!isOpen) {
            $accordion.find(".u-accordion__content").slideDown(300)
        } else {
            $accordion.find(".u-accordion__content").slideUp(300)
        }
    });

 }(window.jQuery, window, document));
(function() {

    $(document).on('content-ready', function(e, currentSlide) {

        var $slideItem = $(currentSlide).closest('.slick-slide').next(':not(.slick-cloned)');
        
        if($slideItem.length <= 0) {
            $slideItem = $(currentSlide).closest('.slick-track').children(':not(.slick-cloned)').eq(0);
        }

        var $slideHeadline = $slideItem.find('.u-teaser__headline'),
            $slidePicture = $slideItem.find('.u-teaser__image'),
            
            $nextTopicTeaser = $('.u-footer__next-topic-teaser');

            $nextTopicTeaser.find('.u-footer__next-topic-headline').html($slideHeadline.html());
            $nextTopicTeaser.find('.u-footer__next-topic-image').html($slidePicture.html());
            $nextTopicTeaser.attr('href', $slideItem.find('.u-main-slider__item').data('page-url'));

            $nextTopicTeaser.show();
    });

    inView('.u-footer__next-topic-teaser, .u-epaper-teaser .u-section__inner').on('enter', function(target) {
        var $target = $(target);

        if($target.hasClass('u-inview-complete')) return;

        anime.timeline({
            easing: 'linear',
            begin: function() {
                $target.addClass('u-inview-complete');
            }
        }).
        add({
            targets: target,
            translateY: ['100%', 0],
            opacity: [0,1]
        });
    });
    $('.u-footer__next-topic-teaser, .u-epaper-teaser .u-section__inner').css({
        'opacity': 0
    });

})();
(function () {

  var Header = function(element) {
    this.$element = $(element);
    this.init();

    this.$element.data('header', this);
  };

  Header.prototype.init = function() {
    this.initMenu();
    this.initSearchForm();
  }

  Header.prototype.initSearchForm = function() {
    var that = this;

    that.$element.find('.u-header__search-button').click(function() {
      that.openSearch();
    });

    that.$element.find('.u-header__search-close-button').click(function() {
      that.closeSearch();
    });
  }

  Header.prototype.openSearch = function() {
    this.$element.removeClass('u-header--search-closed');
    this.$element.addClass('u-header--search-open');
    this.$element.find('.u-header__search-form input').focus();
  }

  Header.prototype.closeSearch = function() {
    this.$element.removeClass('u-header--search-open');
    this.$element.addClass('u-header--search-closed');
  }

  Header.prototype.initMenu = function() {
    var that = this;

    that.$element.find('.u-header__menu-toggle').click(function() {
      that.toggleMenu();
    });
  }

  Header.prototype.toggleMenu = function() {
    var that = this,
        $element = that.$element,
        $menu = $element.find('.u-header__menu');

    if(!$element.hasClass('u-header--menu-open')) {

      $element.removeClass('u-header--menu-closed').addClass('u-header--menu-open u-header--menu-opening');
      $menu.slideDown(300, function() {
        $element.removeClass('u-header--menu-opening');
      });

    } else {

      $element.removeClass('u-header--menu-open').addClass('u-header--menu-close u-header--menu-closing');
      $menu.slideUp(300, function() {
        $element.removeClass('u-header--menu-closing');
      });

    }
  }



   $(document).ready(function() {
     var $header = $('.u-header');

     if($header.length > 0) {
      new Header($header[0])
     }
    
   });

})();
(function () {

  function Slider(element, options) {
      this.$element = $(element);
      this.options = $.extend({}, {    
        fade: true,
        dots: true,
        prevArrow:'<button class="u-slider__prev u-svg-button u-svg-button--style-bordered"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-chevron-left fa-w-10" data-icon="chevron-left" data-prefix="fas" viewBox="0 0 320 512"><path fill="currentColor" d="M35 239L229 45c9-10 24-10 34 0l22 22c10 10 10 25 1 34L131 256l155 155c9 9 9 24-1 34l-22 22c-10 10-25 10-34 0L35 273c-10-9-10-25 0-34z"/></svg></button>',
        nextArrow: '<button class="u-slider__next u-svg-button"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-chevron-right fa-w-10" data-icon="chevron-right" data-prefix="fas" viewBox="0 0 320 512"><path fill="currentColor" d="M285 273L91 467c-9 10-24 10-34 0l-22-22c-10-10-10-25-1-34l155-155L34 101c-9-9-9-24 1-34l22-22c10-10 25-10 34 0l194 194c10 9 10 25 0 34z"/></svg></button>'
      }, options);
      this.slider = null;

      this.init();
  }

  Slider.prototype.init = function () {
    this.slider = this.$element.slick(this.options);
    this.$element.data('slider', this);
  }

  $(document).ready(function() {
      $('.u-slider').each(function() {
        new Slider(this);
      })
  });

})();
(function () {

    $(document).ready(function() {
        $(".fancybox").fancybox({
            transitionIn	:	'elastic',
            transitionOut	:	'elastic',
            speedIn		:	600, 
            speedOut		:	200, 
            type : "image"
         });
    });

})();
(function () {

    $(document).ready(function() {
        setTimeout(function() {
          if(!Cookiebot.consent.marketing) {
            $('.u-vimeo-player__cookie-hint').removeClass('hidden');
          }
        }, 1000);
    });

})();
(function () {

    function YoutubePlayer(element, options) {
        this.$element = $(element);
        this.playerId = this.$element.find('iframe').attr('id');
        this.player = null;

        this.options = $.extend({}, {

        }, options);

        this.init();
    }

    YoutubePlayer.prototype.init = function () {
        var that = this;

        this.player = new YT.Player(this.playerId, {});
    }

    $(document).on('ready', function() {
        $(document).on('content-ready', function () {

            $('.u-youtube-player').each(function (index, element) {
                var $element = $(element);
                if (!$element.data('youtube')) {
                    var playerObject = new YoutubePlayer($element);
                    $element.data('youtube', playerObject);
                }
            });



            var inviewYoutubePlayer = inView('.u-youtube-player').on('enter', function(target) {
                var $target = $(target),
                    playerObject = $target.data('youtube');

                if(playerObject && playerObject.player && playerObject.player.playVideo) {
                    playerObject.player.playVideo()
                }
            }).on('exit', function(target) {
                var $target = $(target),
                    playerObject = $target.data('youtube');

                if(playerObject && playerObject.player && playerObject.player.pauseVideo && playerObject.player.isMuted()) {
                    playerObject.player.pauseVideo()
                }
            });
            destroyInViewOnContentDestroy(inviewYoutubePlayer);

        });
    });

})();